import {
  TSelectGroupOption,
  TSelectOption,
} from "src/ui/DropdownSelect/DropdownSelect"

export function isGroupSelectOption<
  V extends string = string,
  O extends { [key: string]: unknown } = Record<string, unknown>,
>(
  option: TSelectOption<V, O> | TSelectGroupOption<V, O>
): option is TSelectGroupOption<V, O> {
  return "title" in option
}
